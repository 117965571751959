/* eslint-disable import-helpers/order-imports */
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';

import * as Sentry from '@sentry/react';

import './app/main/config/i18next-setup';

import Router from '~/app/main/router/routes/router';

import { APP_ENV } from './env';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';

const tagManagerArgs = {
  gtmId: APP_ENV.GA_TRACKING_ID
};

async function enableMocking() {
  if (APP_ENV.EMAIL_TEST) {
    const { worker } = await import('./tests/mocks/browser');

    return worker.start({
      onUnhandledRequest: 'bypass'
    });
  }

  return;
}

TagManager.initialize(tagManagerArgs);

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://6a01efc4cb47c05c58280509dd744395@o4506933737029632.ingest.us.sentry.io/4506933858009088',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: [
    //   'localhost',
    //   /^https:\/\/dev-api.v2.f162k8s.com.br\//,
    //   /^https:\/\/api.v2.f162k8s.com.br\//
    // ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const container = document.querySelector('#root');

const root = createRoot(container);

enableMocking().then(() => {
  root.render(<Router />);
}); 
